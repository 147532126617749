<template>
  <b-card>
    <div class="custom-container">
      <div class="row">
        <div class="col-12">
          <card>
            <template v-slot:headerTitle>
              <h2 class="card-title"><b>Mis Medallas</b></h2>
            </template>
            <!-- card body -->
            <div class="card-body">
              <template v-if="listaMedallas.length">
                <div class="row justify-content-center list-medallas">
                  <!-- btn enviar medallas -->
                  <vs-button
                    class="col-12 col-lg-5 py-3 myBtn"
                    to="enviar_medallas"
                     
                    size="large"
                  >
                    <div class="row w-100">
                      <div class="col-3">
                        <i class="fas fa-paper-plane mr-1 icon-paper"></i>
                      </div>
                      <div class="col d-flex flex-column align-items-start">
                        <span
                          class="text-white font-weight-bold"
                          style="font-size: 1.5rem"
                          >Enviar medallas</span
                        >
                        <p style="line-height: 1.5">
                          Puedes enviar medallas a tus compañeros...
                        </p>
                      </div>
                    </div>
                  </vs-button>
                  <!-- lista medallas -->
                  <div
                    class="col-12 col-lg-5 bg-dark rounded position-relative"
                    @click="showModalMedalla(medalla)"
                    v-b-modal.modal_medallas
                    v-for="(medalla, index) in listaMedallas"
                    :key="index"
                  >
                    <!-- cantidad medalals -->
                    <vs-tooltip
                      class="position-absolute"
                      style="
                        display: grid;
                        place-items: center;
                        top: 0;
                        right: 0;
                        width: 30px;
                        height: 30px;
                      "
                    >
                      <vs-button class="font-weight-bold" circle danger relief>
                        {{ medalla.personas.length }}
                      </vs-button>
                      <template #tooltip>Cantidad de medallas</template>
                    </vs-tooltip>
                    <div class="row py-3">
                      <!-- imagen medalla -->
                      <div class="col d-flex align-items-center">
                        <img
                          class="w-100"
                          :src="medalla.imagen"
                          :alt="medalla.descripcion"
                        />
                      </div>

                      <div
                        class="col-8 d-flex flex-column justify-content-center"
                        style="gap: 0.2rem"
                      >
                        <!-- descripcion -->
                        <span class="h4 font-weight-bold">{{
                          medalla.descripcion
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="d-flex flex-column align-items-center" v-else>
                <p class="font-weight-bolder h3">No tienes medallas aún...</p>
                <!-- btn enviar medallas -->
                <vs-button
                  class="col-12 col-lg-5 py-3 myBtn"
                  to="enviar_medallas"
                   
                  size="large"
                >
                  <div class="row w-100">
                    <div class="col-3">
                      <i class="fas fa-paper-plane mr-1 icon-paper"></i>
                    </div>
                    <div class="col d-flex flex-column align-items-start ">
                      <span
                        class="text-white font-weight-bold"
                        style="font-size: 1.5rem"
                        >Enviar medallas</span
                      >
                      <p class="text-white" style="line-height: 1.5">
                        Puedes enviar medallas a tus compañeros...
                      </p>
                    </div>
                  </div>
                </vs-button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <!-- modal info medalla -->
    <b-modal
      size="lg"
      id="modal_medallas"
      hide-footer
      hide-header
      body-class="body-modal-custom"
    >
      <b-card class="w-100">
        <div class="row" style="gap: 1rem">
          <!-- medalla info -->
          <div class="col-12 bg-dark rounded position-relative">
            <!-- cantidad medalals -->
            <vs-tooltip
              class="position-absolute"
              style="
                display: grid;
                place-items: center;
                top: 0;
                right: 0;
                width: 30px;
                height: 30px;
              "
            >
              <vs-button class="font-weight-bold" circle danger relief>
                {{ selectedMedalla.personas.length }}
              </vs-button>
              <template #tooltip>Cantidad de medallas</template>
            </vs-tooltip>
            <div class="row">
              <!-- imagen medalla -->
              <div class="col d-flex align-items-center">
                <img
                  class="w-100"
                  :src="selectedMedalla.imagen"
                  :alt="selectedMedalla.descripcion"
                />
              </div>

              <div
                class="col-8 col-lg-10 d-flex flex-column justify-content-center"
                style="gap: 0.2rem"
              >
                <!-- descripcion -->
                <span class="h4 font-weight-bold">{{
                  selectedMedalla.descripcion
                }}</span>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <h3 class="text-capitalize font-weight-bold font-italic">
              listado de usuarios
            </h3>
            <p class="text-white-50 font-italic h4">
              Todos los usuarios que te han enviado ésta medalla, puedes ver su
              nombre, comentario y fecha de emisión...
            </p>
          </div>
          <!-- lista de usuario -->
          <div class="col-12">
            <div
              class="row rounded py-3 mb-3"
              style="background: #0f213f"
              v-for="(user, index) in selectedMedalla.personas"
              :key="index"
            >
              <div
                class="col mb-2 mb-lg-0 d-flex justify-content-center align-items-center"
              >
                <vs-avatar
                  class="rounded"
                  dark
                  size="60"
                  :badge="user.isRecent"
                  badge-position="top-right"
                >
                  <i
                    class="fas fa-user text-primary"
                    style="font-size: 1.5rem"
                  ></i>
                </vs-avatar>
              </div>
              <div
                class="col-12 col-lg-10 d-flex flex-column justify-content-between"
              >
                <span class="font-weight-bold">{{ user.nombre }}</span>
                <p
                  v-if="user.comentario"
                  class="text-white-50"
                  style="line-height: 1.5; font-size: 1rem"
                >
                  {{ user.comentario }}
                </p>
                <span class="text-capitalize">
                  <i class="fas fa-circle-check mr-1"></i> fecha emisión:
                  <em class="text-primary">{{ user.fecha | formatDate }}</em>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-modal>
  </b-card>
</template>

<script>
import moment_timezone from "moment-timezone";
export default {
  name: "PerfilMedallas",
  data() {
    return {
      usuario: {},
      selectedMedalla: {
        id: null,
        descripcion: null,
        imagen: null,
        personas: [
          {
            nombre: null,
            fecha: null,
            comentario: null,
            isRecent: null,
          },
        ],
      },
      listaMedallas: [
        {
          id: null,
          descripcion: null,
          imagen: null,
          personas: [
            {
              nombre: null,
              fecha: null,
              comentario: null,
              isRecent: null,
            },
          ],
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      this.listaMedallas = [];
      await this.getData(this.usuario.id);
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    getCollapseId(medallaId) {
      return `comentario-${medallaId}`;
    },
    async getData(usuarioId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "MedallasPersona/ListByUsuario/" + usuarioId,
        });

        console.log("list medallas... ", res);

        if (res.length > 0) {
          // Itera sobre las medallas y agrega la información de la persona a cada medalla
          for (let index in res) {
            res[index].emisor = await this.getPersonaByID(res[index].emisorId);
          }

          // Utiliza reduce para agrupar las medallas por ID
          const medallasAgrupadas = res.reduce((acumulador, emisor) => {
            const id = emisor.medallas.id;

            // Si ya hay un grupo con este ID, agrégalo; de lo contrario, crea uno nuevo
            if (acumulador[id]) {
              acumulador[id].personas.push({
                comentario: emisor.comentario,
                fecha: emisor.fechaObtencion,
                nombre: emisor.emisor,
                isRecent: this.isRecent(emisor.fechaObtencion),
              });
            } else {
              acumulador[id] = {
                id: emisor.medallas.id,
                imagen: emisor.medallas.imagen,
                descripcion: emisor.medallas.descripcion,
                personas: [
                  {
                    comentario: emisor.comentario,
                    fecha: emisor.fechaObtencion,
                    nombre: emisor.emisor,
                    isRecent: this.isRecent(emisor.fechaObtencion),
                  },
                ],
              };
            }

            return acumulador;
          }, {});

          // Convierte el objeto en un array de objetos
          this.listaMedallas = Object.values(medallasAgrupadas);
          console.log(
            "medallas agrupadas----------------->",
            this.listaMedallas
          );
        } else {
          this.listaMedallas = [];
        }
      } catch (error) {
        this.listaMedallas = [];
        console.log("err", error);
      }
    },

    async getPersonaByID(emisorId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/GetPersona/" + emisorId,
        });
        console.log("emisor... ", `${res.nombres} ${res.apellidos}`);
        return `${res.nombres} ${res.apellidos}`;
      } catch (error) {
        console.log("err", error);
      }
    },
    showModalMedalla(data) {
      this.selectedMedalla = data;
    },
    isRecent(fecha) {
      // Obtener la fecha actual en el formato de moment_timezone
      const currentDate = moment_timezone();

      // Convertir la fecha de emisión a un objeto moment_timezone
      const emissionDate = moment_timezone.tz(fecha, "America/Bogota");

      // Calcular la diferencia en días
      const daysDifference = currentDate.diff(emissionDate, "days");

      // Devolver true si la diferencia es menor a 3 días, false en caso contrario
      return daysDifference < 3;
    },
  },
  filters: {
    formatDate(date) {
      return moment_timezone.tz(date, "America/Bogota").format("MMM D, YYYY");
    },
  },
};
</script>

<style scoped>
.list-medallas {
  max-height: 600px;
  overflow-y: scroll;
  gap: 1rem;
}

.icon-paper {
  font-size: 4rem;
  animation: leftAndRight 2s ease-in-out infinite;
}
@keyframes leftAndRight {
  0%,
  100% {
    transform: rotate(10deg) translateY(0);
  }
  50% {
    transform: rotate(10deg) translateY(-10px);
  }
}
</style>
